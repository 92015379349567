import { Link, NavLink, useNavigate } from "react-router-dom"
// import logo from "../assets/logo.PNG"
import { FaEnvelope, FaLock } from "react-icons/fa"
import { useContext, useState } from "react"
import { Button, FormGroup, Input, Label, Popover, PopoverBody, PopoverHeader, Spinner } from "reactstrap"

import { deleteCookie, postLogin } from "../../myApiCenter"
import { AuthContext } from "../../context/Can"
import { useEffect } from "react"
import MainNav from "../MainNav/MainNav"
import { clearAllCookies } from "../../utils"

const Login = (props) => {
   const [, userDataSetter] = useContext(AuthContext)
   const navigate = useNavigate()
   const [data, setdata] = useState({
      email: "",
      password: ""
   })

   const inputHandler = (e) => {
      setdata({ ...data, [e.target.name]: e.target.value })
   }
   const [popoverOpen, setPopoverOpen] = useState(false)

   const toggle = () => setPopoverOpen(!popoverOpen)

   const [loading, setloading] = useState(false)

   const submitHandler = async (e) => {
      e.preventDefault()
      setloading(true)
      // console.log(data)
      try {
         //  if (props.title === "students") {
         const j = await postLogin(data)
         // console.log(j)
         if (j) {
            document.cookie = `token1=${j.token}`
            userDataSetter({ ...j.user, login: true })
            // console.log(j)

            localStorage.setItem("user", JSON.stringify({ ...j.user, login: true }))
            navigate("/dashboard")
         }
      } catch (error) {
         console.log(error)
      } finally {
         setloading(false)
         setdata({
            email: "",
            password: ""
         })
      }
   }

   useEffect(() => {
      localStorage.clear()
      clearAllCookies()
      deleteCookie("token1")
      userDataSetter({})
      // console.log("effect run")
      return () => {}
   }, [data])

   return (
      <>
         <MainNav />
         <div className="w-100 h-100 login-box">
            <div className="w-100 me-auto d-flex text-primary" style={{ padding: "10px 23px" }}>
               <NavLink className="" to={"/"}>
                  {/* <img className="m-2" src={logo} alt="logo" /> */}
               </NavLink>
            </div>

            <div className="text-primary d-flex flex-column align-items-center  h-50">
               <h1>Welcome to OPTION trade </h1>
               <form onSubmit={submitHandler} className="d-flex  flex-column ">
                  <div className="mb-4  login-input">
                     <i>
                        <FaEnvelope />
                     </i>
                     <input required value={data.email} onChange={inputHandler} name="email" type="email" placeholder="Enter your Email" />
                  </div>
                  <div className="mb-4 login-input">
                     <i>
                        <FaLock />
                     </i>
                     <input
                        required
                        minLength="6"
                        value={data.password}
                        name="password"
                        onChange={inputHandler}
                        type="password"
                        placeholder="Enter your Password"
                     />
                  </div>
                  <div className="mb-4 ">
                     <input
                        required
                        value={data.isSigned}
                        style={{ scale: "1.2" }}
                        onChange={(e) => {
                           setdata({ ...data, isSigned: e.target.checked })
                        }}
                        type="checkbox"
                     />
                     <span className="ms-3">
                        I accept{" "}
                        <span id="Popover1" type="button" style={{ textDecoration: "underline" }} onClick={toggle}>
                           terms and condition
                        </span>
                        <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                           <PopoverHeader>terms and condition</PopoverHeader>
                           <PopoverBody>
                              <p>Risk Disclosures on Derivatives</p>
                              <ul>
                                 <li>9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses.</li>
                                 <li>-----</li>
                                 <li>On an average, loss makers registered net trading loss close to ₹50,000</li>
                                 <li>-----</li>

                                 <li>
                                    Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as
                                    transaction costs
                                 </li>
                                 <li>-----</li>

                                 <li>Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost</li>
                                 <li>-----</li>
                              </ul>
                              <p>
                                 Algorithmic trading carries a high level of risk and may not be suitable for all investors. The strategies used by
                                 our Algorithmic trading bots are based on historical data and do not guarantee future results. Our trading bots may
                                 not perform as expected due to unforeseen market conditions, technical issues, or other factors. Dhanhike does not
                                 guarantee any specific returns or outcomes, and user should be prepared to potentially lose a portion of his/her
                                 investment. Before investing, please consult with a financial advisor to determine if Algorithmic trading is
                                 appropriate for your financial situation and goals. Dhanhike cannot be held responsible for any losses incurred by
                                 using Dhanhike and users assume full responsibility for their investment decisions
                              </p>
                           </PopoverBody>
                        </Popover>
                     </span>
                  </div>
                  <div className="login-btn-grp">
                     {" "}
                     <button type="submit" disabled={loading} className="mt-3">
                        Log In {loading && <Spinner size="sm" color="light" />}
                     </button>
                     <button className="mt-3" onClick={() => navigate("/home")}>
                        Back
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </>
   )
}
export default Login
