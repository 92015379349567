// ** React Imports
import { Fragment, useState, forwardRef } from "react"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import DataTable from "react-data-table-component"
import { ChevronDown, MoreVertical, Edit3, PlusCircle, XCircle, Activity, User } from "react-feather"
import Select from "react-select"

// ** Reactstrap Imports
import { Card, Input, Button, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { customStyles, InputOption, isCurrentDayInRange, isCurrentTimeInRange } from "../../utils"
import { useEffect } from "react"
// import { data } from "../../../../utility/genericServicesData"
// import { genericServicesColumnList } from "../genericServicesOptions"
// import { getGenericServiceAll } from "../../../../utility/myGenericServiceApiCenter"
import { useContext } from "react"
import { stockColumnList, tradeLogColumnList } from "../tableColumn"
import { AuthContext } from "../../context/Can"
import { dummyDataForTable } from "../../dummyData"
import {
   getAllStock,
   getAllStr,
   getClose,
   getStrAsOption,
   getTradeLogsByStrId,
   postForceStopbyStrId,
   postRestartSocketbyStrId
} from "../../myApiCenter"
import MainNav from "../MainNav/MainNav"
import StockTradeModal from "./StockTradeModal"
import toast from "react-hot-toast"
import ErrorUserModal from "./ErrorUserModal"
import CallPutData from "./CallPutData"
import RefreshToast from "../Componant/refreshToast"
import TradeAnalysis from "./TradeAnalysis"

const TradeLog = () => {
   const navigate = useNavigate()
   const [isTradeAnalysisModal, setisTradeAnalysisModal] = useState(false)
   const [data, setdata] = useState([])
   const [currentPage, setCurrentPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(10)
   const [strOption, setstrOption] = useState([])
   const [selectedStr, setselectedStr] = useState({})
   const getStrOption = async () => {
      try {
         const j = await getStrAsOption()
         // console.log(j.length > 0)
         if (j.length > 0) {
            setstrOption(j)
            setselectedStr(j[0])
            const k = await getTradeLogsByStrId(j[0].value)
            setdata([...k.data].reverse())
         }
         //  setdata(j?.data?.str)
         //  console.log(j?.data?.str[0])
      } catch (error) {
         console.log(error)
      }
   }

   const columns = [...tradeLogColumnList]

   const strChangeHandler = async (strOption) => {
      try {
         setselectedStr(strOption)
         const j = await getTradeLogsByStrId(strOption.value)
         // console.log(j)
         setdata([...j.data].reverse())
      } catch (error) {
         console.log(error)
      }
   }
   // ** Function to handle Pagination
   const handlePagination = (page) => {
      setCurrentPage(page.selected)
   }
   const handlePerPage = (e) => {
      setCurrentPage(0)
      setRowsPerPage(parseInt(e.target.value))
   }
   useEffect(() => {
      getStrOption()
      return () => {}
   }, [])
   // console.log(data)
   // ** Custom Pagination

   const CustomPagination = (props) => {
      return (
         <div className="d-flex mt-3 justify-content-between">
            <div>
               {currentPage * rowsPerPage + 1} TO {(currentPage + 1) * rowsPerPage < data.length ? (currentPage + 1) * rowsPerPage : data.length} of{" "}
               {data.length} items
            </div>
            <div className="d-flex gap-3">
               <ReactPaginate
                  previousLabel=""
                  nextLabel=""
                  forcePage={data.length > rowsPerPage ? currentPage : 0}
                  onPageChange={(page) => handlePagination(page)}
                  pageCount={Math.ceil(data.length / rowsPerPage) || 1}
                  breakLabel="..."
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  activeClassName="active"
                  pageClassName="page-item"
                  breakClassName="page-item"
                  nextLinkClassName="page-link"
                  pageLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextClassName="page-item next-item"
                  previousClassName="page-item prev-item"
                  containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
               />

               <div>
                  <Input
                     type="select"
                     id="rows-per-page"
                     value={rowsPerPage}
                     onChange={(e) => {
                        props.onChangeRowsPerPage(e.target.value)
                        handlePerPage(e)
                     }}
                     className="page-selector"
                  >
                     <option value="5">5</option>
                     <option value="7">7</option>
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                  </Input>
               </div>
            </div>
         </div>
      )
   }

   // console.log(data)
   // console.log(selectedStr)
   return (
      <Fragment>
         <MainNav />

         <Card className="m-5 p-3 main-table">
            <div className="react-dataTable react-dataTable-selectable-rows">
               <DataTable
                  customStyles={customStyles}
                  pagination
                  columns={columns}
                  paginationPerPage={rowsPerPage}
                  className="react-dataTable"
                  sortIcon={<ChevronDown size={10} />}
                  paginationComponent={CustomPagination}
                  paginationDefaultPage={currentPage + 1}
                  data={data}
                  subHeader={true}
                  subHeaderComponent={
                     <div className="d-flex p-1 w-100 justify-content-between align-items-center">
                        <h1 className="datatable-title-head">TRADE LOG</h1>
                        <div className="d-flex gap-1 align-items-center w-25 ms-auto">
                           <Button
                              onClick={() => {
                                 navigate(`/tradelog/${selectedStr.value}`)
                              }}
                           >
                              Statics
                           </Button>
                           <Select
                              // isMulti
                              // value={}
                              //   onFocus={strOption?.length === 0 && getStrOption}
                              // options={dummyItemOptions}
                              value={selectedStr.value && strOption.filter((option) => option.value === selectedStr.value)[0]}
                              options={strOption}
                              onChange={strChangeHandler}
                              components={{ Option: InputOption }}
                              className="react-select my-filter-select w-50"
                              placeholder="Select from list"
                           />
                        </div>
                     </div>
                  }
               />
            </div>
         </Card>
      </Fragment>
   )
}
export default TradeLog
