import { Card, Col, Input, Label, ListGroup, Button, Row, ListGroupItem, Form, Spinner, ModalFooter, ModalBody } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useState, useContext, useEffect, useRef } from "react"
import { getErrorUser, getLogsByUserId, getStrAsOption, getTradeLogsByStrId } from "../../myApiCenter"
import toast from "react-hot-toast"
import { AuthContext, StretagyContext } from "../../context/Can"
import { processTradeData, rowChartOptions } from "../../utils"
import MainNav from "../MainNav/MainNav"
import { ArrowLeft } from "react-feather"
import UserIdChanger from "../Componant/UserIdChanger"
import Chart from "react-apexcharts"

const TradeAnalysis = (props) => {
   const { id } = useParams()
   const [tradeData, settradeData] = useState(null)
   const [data, setdata] = useState(null)
   const [loading, setloading] = useState(false)
   const [userData, userDataSetter] = useContext(AuthContext)
   const [idList] = useContext(StretagyContext)
   const [strName, setstrName] = useState("")
   const [dayWiseData, setdayWiseData] = useState(null)
   //    console.log(userData)
   const [chartOptions, setchartOptions] = useState(rowChartOptions)

   const getData = async () => {
      // console.log("called")
      // console.log(password)
      try {
         // setloading(true)
         toast.loading("fetching")
         const k = await getTradeLogsByStrId(id)
         const strOption = await getStrAsOption()
         setstrName(() => strOption.filter((m) => m.value === id)[0].label)
         const j = await getLogsByUserId(userData._id)
         const dataWithSlip = j.data
            .map((h) => {
               delete h._id
               delete h.orderId
               delete h.uniqueOrderId
               return {
                  ...h,
                  slip: parseFloat(h.transactiontype === "BUY" ? (h.price - h.actualPrice).toFixed(2) : (h.actualPrice - h.price).toFixed(2))
               }
            })
            .filter((b) => b.orderStatus === "complete")
         //  console.log(dataWithSlip)

         const tradeDataWithSlip = k.data.map((dt) => {
            let slip = 0
            const filteredUserTradeData = dataWithSlip.filter((d) => {
               const isTradingSymbolMatched = dt.tradingsymbol === d.tradingsymbol
               const isDateMatched = dt.entryTime.split(",")[0] === d.time.split(",")[0] || dt.exitTime.split(",")[0] === d.time.split(",")[0]
               return isTradingSymbolMatched && isDateMatched
            })
            if (filteredUserTradeData.length > 0) {
               slip = filteredUserTradeData.map((i) => i.slip).reduce((total, item) => item + total, 0)
            }
            return {
               ...dt,
               slip: parseFloat(slip.toFixed(2)),
               netPoint: parseFloat(dt.netPoint.toFixed(2)),
               netPointDueToSlip: parseFloat(dt.netPoint.toFixed(2) - slip.toFixed(2))
            }
         })
         settradeData(tradeDataWithSlip)
         setdayWiseData(processTradeData(tradeDataWithSlip))
         setchartOptions(() => {
            return {
               options: {
                  chart: {
                     id: "apexchart-example"
                  },
                  xaxis: {
                     categories: processTradeData(tradeDataWithSlip).map((p) => p.day)
                  },
                  stroke: {
                     width: [0, 4]
                  },
                  title: {
                     text: strName
                  },
                  dataLabels: {
                     enabled: true,
                     enabledOnSeries: [1]
                  },
                  yaxis: {
                     tickAmount: 11, // Control the number of ticks
                     labels: {
                        formatter: (val) => {
                           return parseInt(val) // Ensure labels are integers
                        }
                     }
                  },
                  plotOptions: {
                     bar: {
                        colors: {
                           ranges: [
                              {
                                 from: -Infinity,
                                 to: 0,
                                 color: "#FF0000" // Red color for values less than 0
                              }
                           ]
                        }
                     }
                  },
                  colors: ["#00E396", "#7C7CE5"] // Default colors for positive values
               },
               series: [
                  {
                     name: "DAILY POINT",
                     type: "column",
                     data: processTradeData(tradeDataWithSlip).map((o) => o.totalNetPointForDay)
                  },
                  {
                     name: "TOTAL POINT",
                     type: "line",
                     data: processTradeData(tradeDataWithSlip).map((p) => p.cumulativePoints)
                  }
               ]
            }
         })
         // console.log(processTradeData(tradeDataWithSlip))
      } catch (err) {
         console.log(err)
      } finally {
         toast.dismiss()
         // setloading(false)
         // toast.dismiss()
      }
   }
   //    console.log(props.tradeData)
   useEffect(() => {
      getData()
   }, [id, strName])
   // console.log(data)
   console.log(tradeData)
   console.log(dayWiseData)
   // console.log(stockOption)
   return (
      <>
         <>
            <MainNav />
            <Card className="m-5 h-100 view-user-card">
               <div className="d-flex justify-content-between m-5">
                  <div className="d-flex align-items-center gap-3">
                     <Link to="/tradelog">
                        <ArrowLeft />
                     </Link>
                     <h3>{strName}</h3>
                  </div>
                  {userData?.userRole === "admin" && <UserIdChanger url="/tradelog/" idList={idList} />}
               </div>
               <Row className="h-100">
                  <Chart options={chartOptions.options} series={chartOptions.series} type="line" height={350} />
               </Row>
            </Card>
         </>
      </>
   )
}
export default TradeAnalysis
